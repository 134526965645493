/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

/* import 'bootstrap'; */

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// require jQuery normally
const $ = require('jquery');
 // create global $ and jQuery variables
global.$ = global.jQuery = $;

// any CSS you require will output into a single css file (app.css in this case)
require('./styles/app.scss');
require('bootstrap');





// start the Stimulus application
/*import './bootstrap';*/
